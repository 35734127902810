<!--
* 创建人：邓国伦
* 日 期：
* 描 述：工作计划
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">辅导员工作计划</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <sy-xueqi v-model="xqid" :selectdqxq="true" style="display: inline"></sy-xueqi>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button size="small" icon="el-icon-refresh" @click="reload()"></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delmodre"
          >删除</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_guidang')"
          icon="el-icon-box"
          @click="guidang(null)"
          >归档</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @selection-change="selectRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="xueqi" label="学期" width="200"> </el-table-column>

        <el-table-column prop="kssj" label="时间" width="240">
          <template slot-scope="scope">
            {{ scope.row.kssj }} 至 {{ scope.row.jssj }}
          </template>
        </el-table-column>
        <el-table-column prop="xm" label="辅导员" width="100"> </el-table-column>
        <el-table-column prop="gznr" label="主要工作内容/任务/目标" width="500">
          <template slot-scope="scope">
            {{ scope.row.gznr
            }} <el-tag v-if="scope.row.sfgd === 1" type="danger" size="mini">归档</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="zxqk" label="执行情况"> </el-table-column>

        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="hasPerms('sy_edit')"
              @click="showEdit(scope.row.id)"
              type="text"
              >{{scope.row.sfgd !== 1?'编辑':'查看'}}</el-button
            >
            <el-popconfirm
              class="pl5"
              v-if="hasPerms('sy_delete') && scope.row.sfgd !== 1"
              title="确定删除吗？"
              @confirm="del(scope.row.id)"
            >
              <el-button slot="reference" type="text" size="mini"> 删除 </el-button>
            </el-popconfirm>
            <el-button
              size="mini"
              v-if="hasPerms('sy_guidang') && scope.row.sfgd !== 1"
              @click="guidang(scope.row.id)"
              type="text"
              >归档</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form :model="info" v-if="dialogFormVisible" ref="formRef" :rules="infoRules">
        <el-form-item label="学期" label-width="120px" prop="XQID">
          <sy-xueqi
            v-model="info.XQID"
            :selectdqxq="true"
            style="display: inline"
          ></sy-xueqi>
        </el-form-item>
        <el-form-item label="时间" label-width="120px" prop="SJ">
          <el-date-picker
            v-model="info.SJ"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            destroy-on-close
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作内容" label-width="120px" prop="GZNR">
          <el-input
            v-model="info.GZNR"
            autocomplete="off"
            type="textarea"
            :rows="5"
            placeholder="主要工作内容/任务/目标不能为空"
          ></el-input>
        </el-form-item>
        <el-form-item label="执行情况" label-width="120px" prop="ZXQK">
          <el-input
            v-model="info.ZXQK"
            autocomplete="off"
            type="textarea"
            placeholder="执行情况"
          ></el-input>
        </el-form-item>
        <el-form-item label="" label-width="120px" v-if="info.SFGD===1">
         <el-tag type="danger">已归档</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="info.SFGD!==1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm(info.ID)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import fdy from "../../api/fdy";
import { getAuthorizeButtonColumnList } from "../../api/system";
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: "DESC",
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: "",
      keyValue: "",
      ids: null,
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      info: {
        ID: "",
        XQID: "",
        KSSJ: "",
        JSSJ: "",
        SJ: null,
        GZNR: "",
        ZXQK: "",
      },
      infoRules: {
        XQID: [{ required: true, message: "请选择学期", trigger: "change" }],
        SJ: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        GZNR: [
          { required: true, message: "主要工作内容/任务/目标不能为空", trigger: "blur" },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    "info.SJ": {
      handler(val) {
        this.info.KSSJ = null;
        this.info.JSSJ = null;
        if (val) {
          this.info.KSSJ = val[0];
          this.info.JSSJ = val[1];
        }
      },
      immediate: true,
    },
  },
  components: {
    "sy-xueqi": () => import("../../components/dropdown/dropdown_xueqi"),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns;
      } else {
        this.buttonauth = {};
      }
    });
    this.getDataPageList();
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true;
      } else {
        return false;
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val;
      this.getDataPageList();
    },
    reload() {
      this.$router.go(0);
    },
    // 当前行发生变化
    selectRowChange(rows) {
      this.ids = null;
      this.ids = rows.map((v) => {
        return v.id;
      });
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true;
      fdy
        .getgzjhpagelist({
          queryJson: JSON.stringify({ xqid: this.xqid }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.rows.forEach((m) => {
              m.kssj = this.moment(m.kssj).format("YYYY-MM-DD");
              m.jssj = this.moment(m.jssj).format("YYYY-MM-DD");
            });
            this.tableloading = false;
            this.pagination.page = res.data.page;
            this.pagination.total = res.data.total;
            this.pagination.records = res.data.records;
            this.dataPageList = res.data.rows;
          } else {
            this.tableloading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1;
      this.keyValue = "";
      this.dialogFormVisible = true;
    },
    // 显示编辑弹出框
    showEdit(id) {
      if (id === "") {
        this.$message.error("您没有选中任何数据项，请选中后在操作！");
        return 0;
      } else {
        this.operation = 0;
        fdy.getgzjhformdata({ id: id }).then((res) => {
          if (res.code === 200) {
            Object.keys(res.data).forEach((k) => {
              this.info[k.toUpperCase()] = res.data[k];
            });
            this.info.ID = res.data.id;
            this.info.KSSJ = res.data.kssj;
            this.info.JSSJ = res.data.jssj;
            this.info.SJ = [res.data.kssj, res.data.jssj]
            this.info.GZNR = res.data.gznr;
            this.info.ZXQK = res.data.zxqk;
            this.dialogFormVisible = true;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 保存信息
    saveForm(id) {
      if (this.operation === 1) {
        this.keyValue = "";
        this.info.ID = "";
      } else {
        this.info.ID = id;
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false;
        } else {
          fdy.savegzjh(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.dialogFormVisible = false;
              this.getDataPageList();
              this.$refs.formRef.resetFields();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    del(id) {
      if (!id) {
        this.$message.error("您没有选中任何数据项，请选中后在操作！");
        return 0;
      } else {
        fdy.delgzjh({ id: id }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message);
            this.getDataPageList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 批量删除
    delmodre() {
      if (!this.ids || this.ids.length < 1) {
        this.$message.error("您没有选中任何数据项，请选中后在操作！");
        return 0;
      } else {
        this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            fdy.delgzjh({ ids: this.ids }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message);
                this.getDataPageList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {});
      }
    },
    guidang(id) {
      var dat = null;
      if (id) {
        dat = { id: id };
      } else {
        if (!this.ids || this.ids.length < 1) {
          this.$message.error("您没有选中任何数据项，请选中后在操作！");
          return 0;
        }
        dat = { ids: this.ids }
      }
      fdy.guidanggzjh(dat).then(res => {
           if (res.code === 200) {
                this.$message.success(res.message);
                this.getDataPageList();
              } else {
                this.$message.error(res.message);
              }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0;
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info));
      });
      this.$refs.formRef.resetFields();
    },
  },
};
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
